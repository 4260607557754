<template>
    <el-tabs type="border-card" class="generator-tab">
        <el-tab-pane label="基础设置">
            <div style="display: flex">
                <el-form :model="form" :rules="rules" class="x-edit" style="width:1000px;">
                    <el-form-item label="操作" class="w50">
                        <el-button type="success" :loading="commitLoading" size="small" @click="submitForm">提交</el-button>
                        <el-button type="primary" :loading="packageLoading" size="small" @click="refreshSrc">包路径</el-button>
                        <el-button type="primary" size="small" @click="getLastData">最后一次数据</el-button>
                    </el-form-item>
                    <el-form-item label="创建人" prop="creator" class="w50">
                        <el-input v-model="form.creator" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="消息" class="line">
                        <span v-html="message"></span>
                    </el-form-item>
                    <el-form-item label="JAVA-根目录" prop="jsrc" class="w50">
                        <el-input v-model="form.jsrc" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="VIEW-根目录" prop="vsrc" class="w50">
                        <el-input v-model="form.vsrc" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="JAVA-包路径" prop="jpak" class="w50">
                        <el-cascader v-model="form.jpak" :options="optionsJpak" :props="{ expandTrigger: 'hover', checkStrictly: true }"
                                     size="small" style="width:100%"></el-cascader>
                    </el-form-item>
                    <el-form-item label="VIEW-包路径" prop="vpak" class="w50">
                        <el-cascader v-model="form.vpak" :options="optionsVpak" :props="{ expandTrigger: 'hover', checkStrictly: true }"
                                     size="small" style="width:100%"></el-cascader>
                    </el-form-item>
                    <el-form-item label="SQL-目录" prop="ssrc" class="w50">
                        <el-input v-model="form.ssrc" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="表前缀" prop="sqlPrefix" class="w50">
                        <el-input v-model="form.sqlPrefix" size="small"></el-input>
                    </el-form-item>
                    <el-form-item label="模板类型" prop="type" class="w50">
                        <x-selector-one v-model="form.type" dictType="A_TYPE"/>
                    </el-form-item>
                    <el-form-item label="子表数量" prop="relNum" class="w50">
                        <el-input size="small" v-model="form.relNum" placeholder="请选择" maxlength="2" @input="handleRelNumInput" :disabled="form.type != 2"/>
                    </el-form-item>
                    <el-form-item label="实体名称" prop="name" class="w50">
                        <el-input v-model="form.name" size="small" @input="handleEntryNameInput"></el-input>
                    </el-form-item>
                    <el-form-item label="实体标识" prop="entityCode" class="w50">
                        <el-input v-model="form.entityCode" size="small"/>
                    </el-form-item>
                    <el-form-item label="功能" class="line">
                        <x-check v-model="form.funcs" dictType="A_FUNCTION"/>
                    </el-form-item>
                    <el-form-item label="file-base" class="line">
                        <x-check v-model="form.fileBase" dictType="A_CREATE_BASE"/>
                    </el-form-item>
                    <el-form-item label="file-request" class="line">
                        <x-check v-model="form.fileRequest" dictType="A_CREATE_REQUEST"/>
                    </el-form-item>
                    <el-form-item label="file-response" class="line">
                        <x-check v-model="form.fileResponse" dictType="A_CREATE_RESPONSE"/>
                    </el-form-item>
                    <el-form-item label="file-vue" class="line">
                        <x-check v-model="form.fileVue" dictType="A_CREATE_VUE"/>
                    </el-form-item>
                    <el-form-item label="file-sql" class="line">
                        <x-check v-model="form.fileSql" dictType="A_CREATE_SQL"/>
                    </el-form-item>
                </el-form>
                <el-form :model="form" :rules="rules" style="flex:1;margin-left: 20px;">
                    <x-row :rows="form.relations" :rowModel="relationModel" showBtn="none" style="padding: 0;">
                        <x-row-cell title="子实体名称" width="200">
                            <el-input slot-scope="{row}" v-model="row.name" size="small" @blur="handleRelationNameBlur(row)"></el-input>
                        </x-row-cell>
                        <x-row-cell title="子实体编码" width="200">
                            <el-input slot-scope="{row}" v-model="row.entityCode" size="small"></el-input>
                        </x-row-cell>
                        <x-row-cell title="关系" width="100">
                            <x-selector-one slot-scope="{row}" v-model="row.relation" dictType="A_RELATION"/>
                        </x-row-cell>
                    </x-row>
                </el-form>
            </div>
        </el-tab-pane>
        <el-tab-pane label="主实体字段设置">
            <el-form :model="form">
                <x-row :rows="form.columns" :rowModel="rowModel" :height="550">
                    <x-row-cell title="名称">
                        <el-input slot-scope="{row}" size="small" @blur="handleInputBlur(row)" v-model="row.name"></el-input>
                    </x-row-cell>
                    <x-row-cell title="标识">
                        <el-input slot-scope="{row}" size="small" v-model="row.sign"></el-input>
                    </x-row-cell>
                    <x-row-cell title="类型" width="150">
                        <x-selector-one slot-scope="{row}" v-model="row.type" dictType="A_ATTR"/>
                    </x-row-cell>
                    <x-row-cell title="长度" width="70">
                        <el-input slot-scope="{row}" size="small" v-model="row.size" :disabled="(row.type < 30 || row.type > 99 ? false : true)"></el-input>
                    </x-row-cell>
                    <x-row-cell title="精度" width="70">
                        <el-input slot-scope="{row}" size="small" v-model="row.scale" :disabled="(row.type < 11 ? false : true)"></el-input>
                    </x-row-cell>
                    <x-row-cell title="列表" width="50">
                        <el-switch slot-scope="{row}" v-model="row.list"></el-switch>
                    </x-row-cell>
                    <x-row-cell title="条件" width="50">
                        <el-switch slot-scope="{row}" v-model="row.search"></el-switch>
                    </x-row-cell>
                    <x-row-cell title="匹配" width="110">
                        <x-selector-one slot-scope="{row}" v-model="row.searchMach" dictType="A_SEARCH_MACH" :disabled="!row.search"/>
                    </x-row-cell>
                    <x-row-cell title="选择元属性" width="120">
                        <el-switch slot-scope="{row}" v-model="row.open" :disabled="!form.funcs.includes('7')"></el-switch>
                    </x-row-cell>
                    <x-row-cell title="字典类型">
                        <x-selector-one slot-scope="{row}" filterable v-model="row.dictType" dictType="T_DICT_TYPES"
                                        :disabled="(row.type == 100 ? false : true)"/>
                    </x-row-cell>
                    <x-row-cell title="选择元类型">
                        <x-selector-one slot-scope="{row}" filterable v-model="row.selectorType" dictType="G_SELECTOR"
                                        :disabled="(row.type == 101 ? false : true)"/>
                    </x-row-cell>
                    <x-row-cell title="字典呈现" width="100">
                        <x-selector-one slot-scope="{row}" v-model="row.dictShow" dictType="A_DICT_SHOW" :disabled="(row.type == 100 ? false : true)"/>
                    </x-row-cell>
                    <x-row-cell title="选择类型" width="100">
                        <x-selector-one slot-scope="{row}" v-model="row.selType" dictType="A_SELECT"
                                        :disabled="((row.type == 101 || row.type == 102 || row.type == 103) ? false : true)"/>
                    </x-row-cell>
                </x-row>
            </el-form>
        </el-tab-pane>
        <el-tab-pane :label="rel.name + '实体设置'" v-if="form.type == 2" v-for="(rel, idx) in form.relations" :key="idx">
            <el-form :model="form">
                <x-row :rows="rel.columns" :rowModel="rowModel" :height="550">
                    <x-row-cell title="名称">
                        <el-input slot-scope="{row}" size="small" @blur="handleInputBlur(row)" v-model="row.name"></el-input>
                    </x-row-cell>
                    <x-row-cell title="标识">
                        <el-input slot-scope="{row}" size="small" v-model="row.sign"></el-input>
                    </x-row-cell>
                    <x-row-cell title="类型" width="150">
                        <x-selector-one slot-scope="{row}" v-model="row.type" dictType="A_ATTR"/>
                    </x-row-cell>
                    <x-row-cell title="长度" width="70">
                        <el-input slot-scope="{row}" size="small" v-model="row.size" :disabled="(row.type < 30 || row.type > 99 ? false : true)"></el-input>
                    </x-row-cell>
                    <x-row-cell title="精度" width="70">
                        <el-input slot-scope="{row}" size="small" v-model="row.scale" :disabled="(row.type < 11 ? false : true)"></el-input>
                    </x-row-cell>
                    <x-row-cell title="列表" width="50">
                        <el-switch slot-scope="{row}" v-model="row.list"></el-switch>
                    </x-row-cell>
                    <x-row-cell title="条件" width="50">
                        <el-switch slot-scope="{row}" v-model="row.search"></el-switch>
                    </x-row-cell>
                    <x-row-cell title="匹配" width="110">
                        <x-selector-one slot-scope="{row}" v-model="row.searchMach" dictType="A_SEARCH_MACH" :disabled="!row.search"/>
                    </x-row-cell>
                    <x-row-cell title="字典类型">
                        <x-selector-one slot-scope="{row}" filterable v-model="row.dictType" dictType="T_DICT_TYPES"
                                        :disabled="(row.type == 100 ? false : true)"/>
                    </x-row-cell>
                    <x-row-cell title="选择元类型">
                        <x-selector-one slot-scope="{row}" filterable v-model="row.selectorType" dictType="G_SELECTOR"
                                        :disabled="(row.type == 101 ? false : true)"/>
                    </x-row-cell>
                    <x-row-cell title="字典呈现" width="100">
                        <x-selector-one slot-scope="{row}" v-model="row.dictShow" dictType="A_DICT_SHOW" :disabled="(row.type == 100 ? false : true)"/>
                    </x-row-cell>
                    <x-row-cell title="选择类型" width="100">
                        <x-selector-one slot-scope="{row}" v-model="row.selType" dictType="A_SELECT"
                                        :disabled="((row.type == 101 || row.type == 102 || row.type == 103) ? false : true)"/>
                    </x-row-cell>
                </x-row>
            </el-form>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
    import {createFiles, getDicts, getPackage} from '@/service/system/auto'
    import {LOCAL_STORAGE} from '@/util/storage'
    import {deepCopy} from '@/util/objects';
    import {toPinYin} from '@/util/pinyin'
    import {ruleBuilder} from '@/util/validate';

    const COLUMN = {
        name: '',
        sign: '',
        type: '',
        size: '',
        scale: '',
        list: false,
        search: false,
        searchMach: '',
        open: false,
        dictType: "",
        dictShow: "",
        selType: "",
        selectorType: ""
    }
    const RELATION_CONF = {
        name: '',
        entityCode: '',
        relation: '2',
        columns: []
    }
    export default {
        name: "Auto",
        data() {
            let {regex, required, min} = ruleBuilder;
            return {
                rowModel: deepCopy(COLUMN),
                relationModel: deepCopy(RELATION_CONF),
                message: "信息提示",
                rules: {
                    jsrc: [required()],
                    vsrc: [required()],
                    ssrc: [required()],
                    sqlPrefix: [required()],
                    jpak: [required()],
                    vpak: [required()],
                    name: [required()],
                    entityCode: [required()],
                    creator: [required()],
                    type: [required()]
                },
                commitLoading: false,
                form: {
                    jsrc: LOCAL_STORAGE.get("jsrc") || "",   //java文件根目录
                    vsrc: LOCAL_STORAGE.get("vsrc") || "",   //view文件根目录
                    ssrc: LOCAL_STORAGE.get("ssrc") || "",   //sql文件根目录
                    sqlPrefix: "",//sql前缀
                    type: '1',   //模板类型
                    name: '',   //实体名称
                    entityCode: '', //实体编码
                    jpak: [],   //java文件包路径
                    vpak: [],   //view文件包路径
                    fileBase: ['1', '2', '3', '4', '5', '6'], //生成文件类型
                    fileRequest: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'], //生成文件类型
                    fileResponse: ['1', '2', '3', '4', '5', '6', '7', '8', '9'], //生成文件类型
                    fileVue: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'], //生成文件类型
                    fileSql: ['1'], //生成文件类型
                    funcs: [],  //常规功能项
                    relNum: 0,
                    columns: [],
                    relations: [],
                    creator: LOCAL_STORAGE.get("GENERATOR_CREATOR") || ""
                },
                optionsVpak: [],
                optionsJpak: [],
                packageLoading: false
            }
        },
        mounted() {
            if (LOCAL_STORAGE.get("jsrc")) {
                this.refreshJavaSrc();
            }
            if (LOCAL_STORAGE.get("vsrc")) {
                this.refreshViewSrc();
            }

        },
        methods: {
            submitForm() {
                this.commitLoading = true;
                this.setData(this.form)
                createFiles(this.form).then((response) => {
                    let res = response.data;
                    this.commitLoading = false;
                    this.message = res.msg;
                }).catch((error) => {
                    this.message = error;
                    this.commitLoading = false;
                })
            },
            handleInputBlur(row) {
                row.sign = toPinYin(row.name);
            },
            addColumn() {
                this.form.columns.push(deepCopy(COLUMN));
            },
            delColumn(index) {
                this.form.columns.splice(index, 1);
            },
            refreshSrc() {
                this.refreshJavaSrc();
                this.refreshViewSrc();
                this.refreshSqlSrc();
            },
            refreshJavaSrc() {
                this.packageLoading = true;
                getPackage({src: this.form.jsrc}).then((response) => {
                    this.optionsJpak = response.data;
                    this.packageLoading = false;
                    LOCAL_STORAGE.set("jsrc", this.form.jsrc);
                }).catch((error) => {
                    this.packageLoading = false;
                })
            },
            refreshViewSrc() {
                this.packageLoading = true;
                getPackage({src: this.form.vsrc}).then((response) => {
                    this.optionsVpak = response.data;
                    this.packageLoading = false;
                    LOCAL_STORAGE.set("vsrc", this.form.vsrc);
                }).catch((error) => {
                    this.packageLoading = false;
                })
            },
            refreshSqlSrc() {
                LOCAL_STORAGE.set("ssrc", this.form.ssrc);
            },
            handleEntryNameInput(value) {
                this.form.entityCode = toPinYin(value, true);
            },
            handleRelationNameBlur(row) {
                row.entityCode = toPinYin(row.name, true);
            },
            handleRelNumInput(value) {
                this.form.relations = [];
                let i = 0;
                while (i < value) {
                    this.form.relations.push(deepCopy(RELATION_CONF));
                    i++;
                }
            },
            setData(data) {
                LOCAL_STORAGE.set("G_LAST_DATA", data);
                LOCAL_STORAGE.set("GENERATOR_CREATOR", this.form.creator);
            },
            getLastData() {
                this.form = LOCAL_STORAGE.get("G_LAST_DATA");
            },
        }
    }
</script>

<style lang="scss">
    .generator-tab {
        width: 100%;
        box-sizing: border-box;
        white-space: normal;
    }
</style>
