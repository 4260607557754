import request from '@/util/request'

export function getPackage(data) {
    return request({
        url: '/auto/get-package',
        method: 'post',
        data
    })
}

export function createFiles(data) {
    return request({
        url: '/auto/create-files',
        method: 'post',
        data
    })
}

export function getDicts(data) {
    return request({
        url: '/auto/get-dict',
        method: 'get'
    })
}